<template>
    <div class="add-link">
        <input
            v-model="href"
            class="add-link__input form-control"
            type="text"
            placeholder="Вставьте ссылку или введите название документа"
        />
        <transition name="slide">
            <div
                class="add-link__block"
                v-if="data.length"
                style="transformOrigin: top;
                transition: transform .25s ease-in-out;"
            >
                <button
                    v-for="item in data"
                    :key="item.id"
                    @click="addLink(item.id)"
                    class="v-btn add-link__item"
                >
                    <span
                        class="add-link__item-title"
                        v-html="
                            `<span>${item.highlight.title ||
                                item.source.title}</span>`
                        "
                    ></span>
                </button>
            </div>
        </transition>
        <button
            v-if="href !== link && validURL"
            @click="addLink()"
            class="v-btn v-btn--gold-bordered add-link__button"
        >
            Вставить ссылку на сайт
        </button>
    </div>
</template>

<script>
    import session from '@/api/session'

    export default {
        name: 'AddLinkModal',
        props: {
            link: {
                type: String
            },
            command: {
                type: Function
            }
        },
        data() {
            return {
                href: '',
                timer: null,
                isLoading: false,
                validURL: false,
                data: []
            }
        },
        watch: {
            href: {
                handler: function(val, oldVal) {
                    this.isUrl(val)
                    if (
                        this.link !== val &&
                        !this.validURL &&
                        this.href.length
                    ) {
                        this.sleep(500).then(() => {
                            this.getSearch()
                        })
                    }
                },
                deep: true
            }
        },
        methods: {
            addLink(id) {
                id
                    ? this.command({
                          href: `${location.origin}/knowledge/article/${id}`
                      })
                    : this.command({ href: this.href })
                this.$modal.hide('add-link-modal')
            },
            sleep(ms) {
                return new Promise(resolve => {
                    this.isLoading = true
                    if (this.timer) {
                        clearTimeout(this.timer)
                    }
                    this.timer = setTimeout(resolve, ms)
                })
            },
            async getSearch() {
                this.isLoading = true
                await session
                    .get(`/api/v1/material/search/?search=${this.href}`)
                    .then(response => {
                        this.data = response.data
                    })
                    .catch(error => {
                        this.data = []
                        console.error(error)
                    })
                this.isLoading = false
            },
            isUrl(s) {
                var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                this.validURL = regexp.test(s)
            }
        },
        created() {
            this.href = this.link
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    /deep/ strong {
        color: #000;
    }
    .slide-enter,
    .slide-leave-to {
        transform: scaleY(0);
    }
    .add-link {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 16px;
        &__block {
            position: absolute;
            top: 50px;
            left: 16px;
            right: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #ffffff;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
            padding: 12px 16px;
            max-height: 116px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        &__item {
            margin-bottom: 16px;
            padding: 0;
            background: transparent;
            &:hover,
            &:active {
                background: transparent;
                opacity: 0.7;
            }
            &:last-child {
                margin: 0;
            }
            &-title {
                color: #9d9d9e;
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }
        &__input {
        }
        &__button {
            margin-top: 16px;
        }
    }
</style>
<style>
    .v--modal-overlay {
        background: none;
    }
    .v--modal-box {
        overflow: inherit !important;
    }
</style>
